import './App.css'

import React from 'react'

import Menu from './components/Menu'
import Mapa from './components/Mapa'
import Login from './components/Login'

import { useLogin } from './providers/Login';

const App = () => {

	const { login, setarLogin } = useLogin()

	return (
		<>
			{!login.token && <Login />}
			{login.token && <Menu />}
			{login.token && <Mapa />}
		</>
	);
}

export default App