import React from 'react';
import { Button, Checkbox, Form, Input, message } from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons';

import { useLogin } from '../providers/Login';

const Login = () => {

    const [form] = Form.useForm()
    const { login, setarLogin } = useLogin()

    const onFinish = (values) => {
        fetch('https://api.jupiter.com.br/view/Usuarios/verificarUsuarioSenhaJSON', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
                // 'Content-Type': 'application/json'
            },
            body: new URLSearchParams(values)
        })
            .then(response => response.json())
            .then(data => {
                if (data.success) {
                    setarLogin({
                        usuario: values.usuario,
                        token: data.token
                    })
                } else {
                    message.error(data.message)
                }
                console.log(data)
            });

        console.log('Success:', values);
    }

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    }

    return (
            <Form style={{  margin: '130px auto 0px auto' }} form={form} name="normal_login" className="login-form" initialValues={{ remember: true, }} onFinish={onFinish}>
                <Form.Item name="usuario" rules={[{ required: true, message: 'Insira seu usuário!', },]} >
                    <Input autoFocus prefix={<UserOutlined className="site-form-item-icon" />} placeholder="Usuário" />
                </Form.Item>
                <Form.Item name="senha" rules={[{ required: true, message: 'Insira sua senha!', },]} >
                    <Input prefix={<LockOutlined className="site-form-item-icon" />} type="password" placeholder="Senha" />
                </Form.Item>
                <Form.Item>
                    <Button type="primary" htmlType="submit" className="login-form-button">
                        Entrar
                    </Button>
                </Form.Item>
            </Form>
    )
}

export default Login