import React, { useEffect, useState } from 'react'

export const LoginContext = React.createContext({})

export const LoginProvider = (props) => {

	const [login, setarLogin] = useState({
		token: null,
		usuario: null
	})

	// useEffect(() => {
	// 	setarLogin(50)
	//   }, []);

	return <LoginContext.Provider value={{ login, setarLogin }}>{props.children}</LoginContext.Provider>

}

export const useLogin = () => React.useContext(LoginContext)
