import React, { useEffect, useState } from 'react'

export const LocalizacaoContext = React.createContext({})

export const LocalizacaoProvider = (props) => {

	const [localizacao, setarLocalizacao] = useState({
		lat: null,
		lng: null
	})

	// useEffect(() => {
	// 	setarLocalizacao(50)
	//   }, []);

	return <LocalizacaoContext.Provider value={{ localizacao, setarLocalizacao }}>{props.children}</LocalizacaoContext.Provider>

}

export const useLocalizacao = () => React.useContext(LocalizacaoContext)
