import { BiTargetLock, BiStreetView } from 'react-icons/bi';
import { Input, Modal, Space, Form } from 'antd'
import { useState } from 'react';

import { useLocalizacao } from '../providers/Localizacao';

// eslint-disable-next-line import/no-anonymous-default-export
export default () => {
	const [isModalVisibleLocation, setIsModalVisibleLocation] = useState(false)
	const [form] = Form.useForm()
	const [localLocalizacao, setLocalLocalizacao] = useState({
		lat: null,
		lng: null
	})

	const { localizacao, setarLocalizacao } = useLocalizacao()

	// let itens = [];
	// let k = 0;

	// for (let i = 0; i < 10; i++) {
	// 	itens.push()
	// 	if (i < 9) {
	// 		itens.push(<div key={++k} className="divider div-transparent" />)
	// 	}
	// }

	const handleLocalizar = () => {
		form.validateFields().then(values => {
			setarLocalizacao(values)
			setIsModalVisibleLocation(false)
            form.resetFields()
		})
		.catch(info => {
			console.log('Validate Failed:', info);
		});
	}
	
	const onChange = (e) => {
		if (e.target.id === 'lat') {
			const latLng = e.target.value.split(' ')
			if (latLng.length === 2) {
				form.setFieldsValue({ lat: parseFloat(latLng[0]) })
				form.setFieldsValue({ lng: parseFloat(latLng[1]) })
			}
		}
	}

	return (
		<>
			<nav>
				<li onClick={() => window.open(`http://maps.google.com/maps?q=&layer=c&cbll=${localizacao.lat},${localizacao.lng}`)}><BiStreetView /></li>
				<li onClick={() => setIsModalVisibleLocation(true)}><BiTargetLock /></li>
			</nav>
			<Modal title="Localização" visible={isModalVisibleLocation} okText="Localizar" cancelText="Cancelar" onOk={() => handleLocalizar()} onCancel={() => setIsModalVisibleLocation(false)}>
				<Form form={form} layout="horizontal" labelCol={{ span: 8, }} wrapperCol={{ span: 16, }} initialValues={{ remember: true, }} autoComplete="off">
					<Form.Item label="Latitude" name="lat" value={localLocalizacao.lat} rules={[{required: true, message: 'Por favor preencha a latitude!',},]}>
						<Input onChange={onChange} />
					</Form.Item>
					<Form.Item label="Longitude" name="lng" value={localLocalizacao.lng} rules={[{required: true, message: 'Por favor preencha a longitude!',},]}>
						<Input />
					</Form.Item>
				</Form>
			</Modal>
		</>
	)

}