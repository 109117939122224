import { createRoot } from 'react-dom/client'

import App from './App'
import { LocalizacaoProvider } from './providers/Localizacao'
import { LoginProvider } from './providers/Login'

import './index.css'

const root = createRoot(document.getElementById('root'))
root.render(
    <LoginProvider>
        <LocalizacaoProvider>
            <App />
        </LocalizacaoProvider>
    </LoginProvider>
);

// ReactDOM.render(<ElementoProvider><App /></ElementoProvider>,  );
